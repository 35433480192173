<template>
  <div
    v-if="count > 1"
    class="d-flex justify-content-center"
  >
    <nav class="pagination-container flex-row">
      <AppButton
        v-if="current > 2"
        class="shadow-1"
        :class="current === 3 ? 'd-flex d-md-none' : null"
        :to="noLink ? undefined : (url + 'page=1')"
        title="В начало"
        :variant="BtnVariant.White"
        is-icon
        @click="changePage(1)"
      >
        <svg class="h-100 w-100">
          <use xlink:href="/public/icons/main.svg#keyboard-double-arrow-left" />
        </svg>
      </AppButton>

      <ul class="pagination bg-apply shadow-1">
        <li
          v-for="(i, index) in pages"
          class="page-item"
          :class="{ 'active': current === i, 'd-md-inline-block d-none': isHide(index)}"
        >
          <NuxtLink
            class="page-link px-15 py-12"
            role="button"
            :to="noLink ? undefined : (current === i ? null : url + 'page=' + i)"
            :title="'Страница ' + i"
            :disabled="current === i"
            @click="changePage(i)"
          >
            {{ i }}
          </NuxtLink>
        </li>
      </ul>
      <AppButton
        v-if="current <= count - 2"
        class="shadow-1"
        :class="current === count - 2 ? 'd-flex d-md-none' : null"
        :to="noLink ? undefined : (url + 'page=' + count)"
        title="В конец"
        :variant="BtnVariant.White"
        is-icon
        @click="changePage(count)"
      >
        <svg class="h-100 w-100">
          <use xlink:href="/public/icons/main.svg#keyboard-double-arrow-right" />
        </svg>
      </AppButton>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { BtnVariant } from "~/composables/enums/BtnVariant";

const route = useRoute();

const props = defineProps({
  perPage: { type: Number, required: true },
  page: { type: Number, default: undefined },
  itemsCount: { type: Number, required: true },
  noLink: { type: Boolean, default: false },
});

const emits = defineEmits(['changePage']);

//-----VARIABLES-----\\
const maxLink = 5;

//-----COMPUTED-----\\
const count = computed<number>((): number => Math.ceil(props.itemsCount / props.perPage));
const current = computed<number>((): number => {
  if (props.page === undefined) {
    return route.query.page ? Number(route.query.page) : 1
  } else {
    return props.page;
  }
});
const url = computed<string>((): string => {
  const params = [];
  for (const key in route.query) {
    if (key !== 'page') {
      params.push(key + '=' + route.query[key]);
    }
  }

  return route.path + '?' + params.join('&') + (params.length === 0 ? '' : '&');
});
const pages = computed<number[]>(():number[] => {
  let start: number;
  let end: number;

  if (count.value < maxLink) {
    start = 1;
    end = count.value;
  } else if (current.value < 3) {
    start = 1;
    end = 5;
  } else if (current.value > count.value - 3) {
    start = (count.value > maxLink ? count.value - maxLink : maxLink - count.value) + 1;
    end = count.value;
  } else {
    start = current.value - 2;
    end = current.value + 2;
  }

  const arr = [];
  for (let i = start; i <= end; i++) {
    arr.push(i);
  }

  return arr;
});

//-----METHODS-----\\
function isHide(index: number) {
  if (pages.value.length < 5) {
    return false;
  }

  if (current.value < 3) {
    return index > 2;
  } else if (current.value > count.value - 2) {
    return index < 2;
  }
  return index === 0 || index === 4;
}
function changePage(page: number) {
  if (page === current.value) {
    return;
  }
  emits('changePage', page);
}
</script>

<style>
/*@media (max-width: 767px) {*/
/*  .pagination > .page-item:first-child, .pagination > .page-item:last-child {*/
/*    display: none;*/
/*  }*/
/*}*/
</style>
